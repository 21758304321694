import { Card, Col, Modal, Row } from 'antd';
import { useState } from 'react';

const { Meta } = Card;
export default function Projects() {
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  return (
    <div
      className="projects"
      style={{
        display: 'flex',
        margin: 'auto',
        width: '85vw',
      }}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Card
            hoverable
            bordered={false}
            cover={
              <video autoPlay loop muted playsInline>
                <source
                  src="https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/lulu+preview.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            }
            onClick={() => setModal1(true)}
          >
            <Meta
              description="React | MongoDB | Node.js | Express | CSS"
              title="Lululemon Product Page Mockup"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            hoverable
            bordered={false}
            cover={
              <video autoPlay loop muted playsInline>
                <source
                  src="https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/ascend+preview.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            }
            onClick={() => setModal2(true)}
          >
            <Meta
              description="React-Native | Expo | Sketch | MongoDB | Firebase"
              title="Ascend Fitness App"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            hoverable
            bordered={false}
            cover={
              <video autoPlay loop muted playsInline>
                <source
                  src="https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/loader+preview.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            }
            onClick={() => setModal3(true)}
          >
            <Meta
              description="PostgreSQL | MongoDB | NGINX | Loader.io | New Relic | AWS"
              title="E-Commerce Database and System Design"
            />
          </Card>
        </Col>
      </Row>

      <Modal
        centered
        footer={null}
        visible={modal1}
        width={1000}
        onCancel={() => setModal1(false)}
        onOk={() => setModal1(false)}
      >
        <Card
          cover={
            <img
              alt="example"
              src="https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/luluexample.gif"
            />
          }
          onClick={() => setModal1(false)}
        >
          <Meta
            description="React | MongoDB | Node.js | Express | CSS"
            title="Lululemon Product Page Mockup"
          />
        </Card>
      </Modal>

      <Modal
        centered
        footer={null}
        visible={modal2}
        width={1000}
        onCancel={() => setModal2(false)}
        onOk={() => setModal2(false)}
      >
        <Card
          cover={
            <img
              alt="example"
              src="https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/ascend.gif"
            />
          }
          onClick={() => setModal2(false)}
        >
          <Meta
            description="React-Native | Expo | Sketch | MongoDB | Firebase"
            title="Ascend Fitness App"
          />
        </Card>
      </Modal>

      <Modal
        centered
        footer={null}
        visible={modal3}
        width={1000}
        onCancel={() => setModal3(false)}
        onOk={() => setModal3(false)}
      >
        <Card
          cover={
            <img
              alt="example"
              src="https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/loaderiocapture.gif"
            />
          }
          onClick={() => setModal3(false)}
        >
          <Meta
            description="PostgreSQL | MongoDB | NGINX | Loader.io | New Relic | AWS"
            title="E-Commerce Database and System Design"
          />
        </Card>
      </Modal>
    </div>
  );
}
