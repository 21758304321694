import { ArrowUpOutlined } from '@ant-design/icons';
import { BackTop, Grid, Layout } from 'antd';
import { useState } from 'react';

import { FadeInSection } from './components//animations/fadeinsection';
import About from './components/about';
import FadeSlideInSection from './components/animations/fadeslideinsection';
import Contact from './components/contact';
import DownArrow from './components/downarrow';
import FooterInfo from './components/footer';
import LandingPage from './components/landing';
import MobileAbout from './components/mobile/mobileabout';
import MobileContact from './components/mobile/mobilecontact';
import MobileFooter from './components/mobile/mobilefooter';
import MobileLanding from './components/mobile/mobilelanding';
import MobileNavBar from './components/mobile/mobilenav';
import MobileProjects from './components/mobile/mobileprojects';
import MobileResume from './components/mobile/mobileresume';
import NavBar from './components/navbar';
import Projects from './components/projects';
import Quote from './components/quote';
import Resume from './components/resume';

import './App.css';
import './dark.css';
import 'antd/dist/antd.min.css';

const { Header, Footer, Content } = Layout;
const { useBreakpoint } = Grid;

function App() {
  const screens = useBreakpoint();

  const [darkmodeHooks, setdarkmodetoggle] = useState(false);
  const body = document.querySelector('body');

  // Enable Dark Mode
  const enableDarkMode = () => {
    body.classList.add('dark-mode');
  };

  // Disable Dark Mode
  const disableDarkMode = () => {
    body.classList.remove('dark-mode');
  };

  if (darkmodeHooks !== false) {
    enableDarkMode();
  } else {
    disableDarkMode();
  }
  return (
    <div className="App">
      <Layout>
        <BackTop
          style={{
            height: 40,
            width: 40,
            lineHeight: '40px',
            borderRadius: 4,
            backgroundColor: 'none',
            color: '#004EFF',
            textAlign: 'center',
            fontSize: 50,
          }}
        >
          <ArrowUpOutlined />
        </BackTop>
        <Header>
          {screens['md'] && (
            <NavBar
              darkmodeHooks={darkmodeHooks}
              setdarkmodetoggle={setdarkmodetoggle}
              style={{ width: 'full' }}
            />
          )}
          {!screens['md'] && (
            <MobileNavBar
              darkmodeHooks={darkmodeHooks}
              setdarkmodetoggle={setdarkmodetoggle}
            />
          )}
        </Header>
        <Content style={{ padding: '0px 25px', marginTop: '4.9vh' }}>
          <FadeInSection>{screens['md'] && <LandingPage />}</FadeInSection>
          {!screens['md'] && <MobileLanding />}
          {screens['md'] && <DownArrow />}
          <div className="longbar" />
          <FadeInSection>{screens['md'] && <Resume />}</FadeInSection>
          <FadeInSection>{!screens['md'] && <MobileResume />}</FadeInSection>
          <div className="bar" />
          <FadeInSection>{screens['md'] && <Projects />}</FadeInSection>
          <FadeInSection>{!screens['md'] && <MobileProjects />}</FadeInSection>
          <div className="bar" />
          <FadeSlideInSection>
            <Quote />
          </FadeSlideInSection>
          <div className="bar" />

          {screens['md'] && <About />}

          {!screens['md'] && <MobileAbout />}
          {screens['md'] && <Contact />}

          {!screens['md'] && <MobileContact />}
        </Content>
        <Footer style={{ padding: '0 25px' }}>
          {screens['md'] && <FooterInfo />}

          {!screens['md'] && <MobileFooter />}
        </Footer>
      </Layout>
    </div>
  );
}

export default App;
