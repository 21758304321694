// /* eslint-disable no-unused-vars */
// import { getAnalytics } from "firebase/analytics";
// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";

// import firebase from "firebase/compat/app";

//  import "firebase/compat/auth";
//  import "firebase/compat/database";
// import "firebase/compat/firestore";
// import Axios from "axios";

// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDPpMn8bPXsDymh-czMaokozSEnM2CfPjs",
//   authDomain: "contact-327fa.firebaseapp.com",
//   projectId: "contact-327fa",
//   storageBucket: "contact-327fa.appspot.com",
//   messagingSenderId: "188160320346",
//   appId: "1:188160320346:web:009806d8b433a9a7ba4963",
//   measurementId: "G-MNF7714HRJ"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// const db = firebase.firestore();

// export { Axios, db };

import firebase from 'firebase/compat/app'; // doing import firebase from 'firebase' or import * as firebase from firebase is not good practice.
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import Axios from 'axios';

// Initialize Firebase
let config = {
  apiKey: 'AIzaSyDPpMn8bPXsDymh-czMaokozSEnM2CfPjs',
  authDomain: 'contact-327fa.firebaseapp.com',
  projectId: 'contact-327fa',
  storageBucket: 'contact-327fa.appspot.com',
  messagingSenderId: '188160320346',
  appId: '1:188160320346:web:009806d8b433a9a7ba4963',
  measurementId: 'G-MNF7714HRJ',
};

firebase.initializeApp(config);

const db = firebase.firestore();

export { Axios, db };
