import { Typography } from 'antd';

const { Text } = Typography;
export default function Quote() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Text style={{ color: '#004EFF', fontSize: '3rem', textAlign: 'center' }}>
        &quot;More is lost by indecision than wrong decision&quot;
      </Text>
    </div>
  );
}
