import { BulbOutlined } from '@ant-design/icons';
import { Button, Col, Menu, Row } from 'antd';
import { Link } from 'react-scroll';

export default function NavBar({ setdarkmodetoggle, darkmodeHooks }) {
  const onDarkmodeClick = () => {
    setdarkmodetoggle(!darkmodeHooks);
  };

  return (
    <div>
      <Menu mode="horizontal" style={{ borderBottom: 'none' }}>
        <Row style={{ width: '100%' }}>
          <Col flex={8} style={{ display: 'flex' }}>
            <Menu.Item>
              <Link
                activeClass="active"
                className="navbar-item fontSmall"
                duration={500}
                offset={-60}
                smooth={true}
                spy={true}
                style={{ color: '#004EFF', fontWeight: '500' }}
                to="landing"
              >
                JEREMY KEN HO
              </Link>
            </Menu.Item>
          </Col>
          <Col
            flex={1}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Menu.Item>
              <Link
                activeClass="active"
                className="navbar-item fontSmall"
                duration={500}
                offset={-60}
                smooth={true}
                spy={true}
                style={{ color: '#004EFF', fontWeight: '500' }}
                to="resume"
              >
                RESUME
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link
                activeClass="active"
                className="navbar-item fontSmall"
                duration={500}
                offset={-60}
                smooth={true}
                spy={true}
                style={{ color: '#004EFF', fontWeight: '500' }}
                to="projects"
              >
                PROJECTS
              </Link>
            </Menu.Item>

            <Menu.Item>
              {' '}
              <Link
                activeClass="active"
                className="navbar-item fontSmall"
                duration={500}
                offset={-60}
                smooth={true}
                spy={true}
                style={{ color: '#004EFF', fontWeight: '500' }}
                to="about"
              >
                ABOUT
              </Link>
            </Menu.Item>

            <Menu.Item>
              <Link
                activeClass="active"
                className="navbar-item fontSmall"
                duration={500}
                offset={-60}
                smooth={true}
                spy={true}
                style={{ color: '#004EFF', fontWeight: '500' }}
                to="contact"
              >
                CONTACT
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Button
                className="dark-mode-button top-dark-mode-button"
                icon={<BulbOutlined />}
                style={{ color: '#004EFF', fontWeight: '500' }}
                type="text"
                onClick={onDarkmodeClick}
              />
            </Menu.Item>
          </Col>
        </Row>
      </Menu>
    </div>
  );
}
