import { MenuOutlined, BulbOutlined } from '@ant-design/icons';
import { Drawer, Button } from 'antd';
import { useState } from 'react';
import { Link } from 'react-scroll';

import 'antd/dist/antd.min.css';

export default function MobileNavBar({ setdarkmodetoggle, darkmodeHooks }) {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const onDarkmodeClick = () => {
    setdarkmodetoggle(!darkmodeHooks);
  };

  return (
    <div
      className="navMenucontainer"
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#004EFF',
        height: '5vh',
        padding: '0px 0px',
      }}
    >
      <Link
        activeClass="active"
        className="navbar-item fontSmall"
        duration={500}
        offset={-60}
        smooth={true}
        spy={true}
        style={{
          color: 'white',
          fontWeight: '500',
          width: '50%',
          fill: 'white',
        }}
        to="landing"
      >
        JEREMY KEN HO
      </Link>

      <MenuOutlined
        className="navbar-item fontSmall"
        style={{ fill: 'white', color: 'white', fontWeight: '500' }}
        onClick={showDrawer}
      />

      <Drawer
        closable={false}
        placement="right"
        visible={visible}
        width="125"
        onClose={onClose}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Link
            style={{
              color: '#004EFF',
              fontWeight: '500',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              className="dark-mode-button top-dark-mode-button"
              icon={<BulbOutlined />}
              style={{ color: '#004EFF', fontWeight: '500' }}
              type="text"
              onClick={onDarkmodeClick}
            />
          </Link>

          <Link
            activeClass="active"
            className="navbar-item fontSmall"
            duration={500}
            offset={-60}
            smooth={true}
            spy={true}
            style={{ color: '#004EFF', fontWeight: '500' }}
            to="resume"
            onClick={onClose}
          >
            RESUME
          </Link>
          <br></br>
          <Link
            activeClass="active"
            className="navbar-item fontSmall"
            duration={500}
            offset={-60}
            smooth={true}
            spy={true}
            style={{ color: '#004EFF', fontWeight: '500' }}
            to="projects"
            onClick={onClose}
          >
            PROJECTS
          </Link>
          <br></br>

          <Link
            activeClass="active"
            className="navbar-item fontSmall"
            duration={500}
            offset={-60}
            smooth={true}
            spy={true}
            style={{ color: '#004EFF', fontWeight: '500' }}
            to="about"
            onClick={onClose}
          >
            ABOUT
          </Link>
          <br></br>
          <Link
            activeClass="active"
            className="navbar-item fontSmall"
            duration={500}
            offset={-60}
            smooth={true}
            spy={true}
            style={{ color: '#004EFF', fontWeight: '500' }}
            to="contact"
            onClick={onClose}
          >
            CONTACT
          </Link>
        </div>
      </Drawer>
    </div>
  );
}
