import { Col, Row, Typography } from 'antd';

const { Text } = Typography;
export default function About() {
  return (
    <div
      className="about"
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '25%',
        marginTop: '0px',
        marginBottom: '8vh',
      }}
    >
      <Row gutter={[24]}>
        <Col span={12}>
          <Text
            style={{ fontSize: '.85rem', color: '#004EFF', fontWeight: '575' }}
          >
            ABOUT
          </Text>
          <br />
          <br />
          <Text>
            Jeremy&apos;s ever-growing curiosity has fueled him into the world
            of engineering, both the physical aspects when he was working with
            embedded systems and industrial computing hardware, and now in the
            software field.
          </Text>{' '}
          <br />
          <br />
          <Text>
            He&apos;s motivated to build beautiful responsive applications that
            can one day bring a positive change to our world.
          </Text>
          <br />
          <br />
        </Col>
        <Col span={12}>
          <br />
          <br />
          <Text>
            On days he&apos;s not at his desk programming, you can find him
            either in the garage wrenching on project cars, out in the park for
            a run, or in the mountains bouldering with friends. He also enjoys
            playing tennis, cooking, home DIY projects, music festivals, and
            taking his dogs out for walks.
          </Text>
        </Col>
      </Row>
      <img
        alt="icon"
        className="aboutlefticon"
        src="https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/SVG/Group+13.svg"
      />
    </div>
  );
}
