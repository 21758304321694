import { Col, Row, Typography } from 'antd';

const { Text, Link } = Typography;

export default function FooterInfo() {
  return (
    <div className="footer-container">
      <Row gutter={[48, 48]}>
        <Col span={8}>
          <Text>Email</Text>
          <br></br>
          <Link
            href="mailto:contact@jeremyho.dev"
            style={{ color: '#004EFF' }}
            target="_blank"
          >
            contact@jeremyho.dev
          </Link>
        </Col>
        <Col span={8}>
          <Text>Phone</Text>
          <br></br>
          <Link href="tel:6266771179" style={{ color: '#004EFF' }}>
            +1-626-677-1179
          </Link>
        </Col>
        <Col span={8}>
          <Text>LinkedIn</Text>
          <br></br>
          <Link
            href="https://www.linkedin.com/in/jeremyho1124/"
            style={{ color: '#004EFF' }}
            target="_blank"
          >
            linkedin.com/in/jeremyho1124
          </Link>
        </Col>
      </Row>
   
    </div>
  );
}
