import { ArrowDownOutlined } from '@ant-design/icons';
import { Link } from 'react-scroll';

export default function DownArrow() {
  return (
    <div
      className="downarrow"
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '15vh',
      }}
    >
      <Link
        activeClass="active"
        className="navbar-item fontSmall"
        duration={500}
        offset={-60}
        smooth={true}
        spy={true}
        to="resume"
      >
        <ArrowDownOutlined
          className="arrowanimation"
          style={{ fontSize: '3rem', color: '#004EFF' }}
        />
      </Link>
    </div>
  );
}
