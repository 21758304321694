import { Col, Row, Typography } from 'antd';

const { Text } = Typography;
export default function MobileResume() {
  return (
    <div
      className="resume-container"
      style={{
        margin: 'auto',
        width: '85vw',
      }}
    >
      <object
        alt="resume left icon"
        className="resumelefticonmobile"
        data="https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/SVG/Group+-7.svg"
        type="image/svg+xml"
      />

      <div
        className="resume"
        style={{
          margin: '',
          marginTop: '0px',
          marginBottom: '0px',
        }}
      >
        <Col span={24}>
          <Row>
            <Text
              style={{
                fontSize: '.75rem',
                color: '#004EFF',
                fontWeight: '575',
              }}
            >
              TECHNICAL SKILLS
            </Text>
            <br />
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Text style={{ fontSize: '1rem', fontWeight: '500' }}>
                Front End
              </Text>
              <br />
              <ul
                style={{
                  'list-style-type': 'none',
                  'padding-inline-start': '0px',
                }}
              >
                {' '}
                <li>Typescript </li>
                <li>JavaScript </li>
                <li>React </li>
                <li>ASP.NET Core Blazor </li>
                <li>HTML5</li>
                <li>Tailwind CSS </li>
                <li>Storybook </li>
              </ul>
            </Col>
            <Col span={12}>
              <Text style={{ fontSize: '1rem', fontWeight: '500' }}>
                Back End{' '}
              </Text>
              <br />
              <ul
                style={{
                  'list-style-type': 'none',
                  'padding-inline-start': '0px',
                }}
              >
                <li>Node.js </li>
                <li>Express </li>
                <li>GraphQL </li>
                <li>MongoDB </li>
                <li>PostreSQL </li>
                <li>Microsoft SQL </li>
              </ul>
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Text style={{ fontSize: '1rem', fontWeight: '500' }}>
                Testing + Development{' '}
              </Text>
              <br />
              <ul
                style={{
                  'list-style-type': 'none',
                  'padding-inline-start': '0px',
                }}
              >
                <li>Jest </li>
              <li>bUnit </li>
                <li>AWS Amplify</li>
                <li>AWS EC2 </li>
                <li>Docker </li>
                <li>R6 </li>
                <li>NIGINX </li>
                <li>Firebase </li>
              </ul>
            </Col>
            <Col span={12}>
              <Text style={{ fontSize: '1rem', fontWeight: '500' }}>
                Other{' '}
              </Text>
              <br />
              <ul
                style={{
                  'list-style-type': 'none',
                  'padding-inline-start': '0px',
                }}
              >
                <li>Git </li>
              <li>Azure DevOps </li>
                <li>NPM </li>
                <li>Expo </li>
                <li>Webpack </li>
                <li>Babel </li>
                <li>Apollo - GraphQL </li>
                <li>Adobe Photoshop </li>
              </ul>
            </Col>
          </Row>


          <Row span={14}>
          <Text
              style={{
                fontSize: '.75rem',
                color: '#004EFF',
                fontWeight: '570',
              }}
            >
              <br />
              PROFESSIONAL EXPERIENCE
            </Text>
            <br />
            <Text style={{ fontSize: '1rem', fontWeight: '500' }}>
              Securiport - Washington, DC | Software Engineer
            </Text>
            <br />
            <Text style={{ fontStyle:'italic' }}>
              Washington, DC | Hybrid | Dec 2021-Current
            </Text>
<br/>
           
            <ul>
              <li>
              Contributed to diverse projects spanning various technologies, including AngularJS, ASP.NET WebForms, and .NET Core, as well 
as transitioning projects to modern frameworks like .NET MAUI, Blazor, TypeScript/React. 
              </li>
              <li>
              Mentored and improved onboarding process for new software and QA engineers.
              </li>
              <li>
              Migrated and documented multiple applications resulting in a vastly improved codebase readability and maintainability.{' '}
              </li>
              <li>
              Enhanced development efficiency tenfold by optimizing code consistency, increasing test coverage, and review processes.{' '}
              </li>
              <li>
              Increased collaboration within the team by creating better workflow processes and enforcing code review sessions.
              </li>
            </ul>




            
            <Text
              style={{
                fontSize: '.75rem',
                color: '#004EFF',
                fontWeight: '570',
              }}
            >
              <br />
              PERSONAL PROJECTS
            </Text>
            <br />
            <Text style={{ fontSize: '1rem', fontWeight: '500' }}>
              Back End | E-Commerce Database and System Design
            </Text>

            <Text>
              Web-scaled and redesigned the back end of an existing application
              with focus on test driven development
            </Text>
            <ul>
              <li>
                Implemented PostgreSQL to manage users, reviews, and product
                tables.
              </li>
              <li>
                Optimized the database with indexing to achieve an average 5ms
                response time per request.
              </li>
              <li>
                Seeded the database with 20 million realistic entries and
                deployed onto an AWS EC2 t2.micro instance.{' '}
              </li>
              <li>
                Horizontally scale to 3 servers w/ round-robin load balancing
                NGINX caching, increased RPS 600 to 4000 with less than 100ms
                response time using Loader.io{' '}
              </li>
            </ul>
            <Text style={{ fontSize: '1rem', fontWeight: '500' }}>
              Front End | Product Page Mockup
            </Text>
            <br />
            <Text>
              Recreated the Lululemon product detail page with emphasis in
              RESTful API
            </Text>
            <ul>
              <li>
                Collaborated with a team encapsulating each member’s module to
                embrace service oriented architecture.
              </li>
              <li>
                Built a dynamically rendering front-end app module utilizing
                React-Boostrap libraries to serve an interactive user experience
                creating a seamless floating navigation and product information
                bar through use of react hooks.
              </li>
              <li>
                Designed a search feature within the navigation bar with
                conditional querying and drop down overlay of the results.
              </li>
              <li>
                Integrated a sliding carousel to display an array of products
                based on the API call.
              </li>
            </ul>
            <Text style={{ fontSize: '1rem', fontWeight: '500' }}>
              Mobile Web App | Ascend Workout tracker
            </Text>

            <Text>
              Conceptualized and deployed a mobile web app along with a team of
              engineers embracing agile software development
            </Text>
            <ul>
              <li>
                Implemented a conditionally rendering log-in and sign-up page
                utilizing Google Firebase Authentication Services.
              </li>
              <li>
                Constructed a homepage feed with React-Native elements -
                scrolling cards that displayed saved user workout stats and a
                progress circle with weekly workout goals.
              </li>
              <li>
                Scheduled daily scrum meetings to scale the scope of the work
                and ensure cross compatibility between the screens.
              </li>
            </ul>
          </Row>
        </Col>
        <img
          alt="resume right icon"
          className="resumerighticonmobile"
          src="https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/SVG/wrench+-1.svg"
        />
      </div>
      <a
        download="Jeremy Ho resume"
        href="https://s3.us-west-1.amazonaws.com/jeremyho.dev/Jeremy+Ken+Ho+Resume.pdf"
        rel="noopener noreferrer"
        style={{
          fontSize: '.75rem',
          color: '#004EFF',
          textDecoration: 'underline',
          fontWeight: '550',
        }}
        target="_blank"
      >
        {' '}
        RESUME DONWLOAD →
      </a>
    </div>
  );
}
