import { blue } from '@ant-design/colors';
import { SmileOutlined } from '@ant-design/icons';
import { Col, Result, Row, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import { useState } from 'react';

import { Axios } from '../firebase/firebaseConfig';

import { FadedownEffect } from './animations/fadedowneffect';
import { TypewriterEffect } from './animations/typewritereffect';

const { Text } = Typography;
const { TextArea } = Input;

export default function Contact() {
  const [submitted, setSubmit] = useState(false);

  return (
    <div
      className="contact"
      style={{
        backgroundImage:
          "url('https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/backgroundblue.jpg')",
        backgroundSize: 'cover',
        backgroundColor: blue.primary,
        backgroundRepeat: 'no-repeat',
        height: '65vh',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        position: 'relative',
        left: '-25px',
        width: '99vw',
        marginBottom: '5vh',
      }}
    >
      <div
        style={{
          height: '50%',
          display: 'grid',
          width: '65%',
        }}
      >
        <Text style={{ color: 'white', fontSize: '.85rem', fontWeight: '575' }}>
          CONTACT
        </Text>
        {submitted === false ? (
          <div>
            <TypewriterEffect>
              <Text style={{ color: 'white', fontSize: '2.2rem' }}>
                Let&apos;s make something beautiful.
              </Text>
            </TypewriterEffect>

            <FadedownEffect>
              <div style={{ width: '100%' }}>
                <Formik
                  initialValues={{ name: '', email: '', message: '' }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.name) {
                      errors.name = 'Required';
                    } else if (values.name.length > 15) {
                      errors.name = 'Must be 15 characters or less';
                    }

                    if (!values.message) {
                      errors.message = 'Required';
                    } else if (values.message.length > 2000) {
                      errors.message = 'Must be 2000 characters or less';
                    }

                    if (!values.email) {
                      errors.email = 'Required';
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(
                        values.email,
                      )
                    ) {
                      errors.email = 'Invalid email address';
                    }

                    return errors;
                  }}
                  onSubmit={(values) => {
                    Axios.post(
                      'https://us-central1-contact-327fa.cloudfunctions.net/submit',
                      values,
                    )
                      .then(() => {
                        setSubmit(true);
                      })
                      .catch(() => {});
                  }}
                >
                  {() => (
                    <Form onSubmit={Formik.handleSubmit}>
                      <Row gutter={[16, 16]} style={{ paddingTop: '1rem' }}>
                        <Col span={12}>
                          <Input
                            className="input"
                            name="name"
                            placeholder="NAME"
                            style={{
                              background: 'transparent',
                              borderColor: 'white',
                            }}
                            onChange={Formik.handleChange}
                          />
                        </Col>
                        <Col span={12}>
                          <Input
                            className="input"
                            name="email"
                            placeholder="EMAIL"
                            style={{
                              background: 'transparent',
                              borderColor: 'white',
                            }}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]} style={{ paddingTop: '1rem' }}>
                        <Col span={24}>
                          <TextArea
                            className="input"
                            name="message"
                            placeholder="MESSAGE"
                            rows={4}
                            style={{
                              background: 'transparent',
                              borderColor: 'white',
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <div
                          style={{
                            paddingTop: '1rem',
                            width: '100%',
                            display: 'flex',
                            alignItem: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <SubmitButton>SUBMIT</SubmitButton>
                        </div>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </FadedownEffect>
          </div>
        ) : (
          <div className="contact-success">
            <Result
              icon={<SmileOutlined style={{ color: 'white' }} />}
              style={{ color: 'white', fontColor: 'white' }}
              title="Thanks for that awesome message. You’re awesome, you know that?"
            />
          </div>
        )}
      </div>
    </div>
  );
}
