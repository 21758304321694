import { ArrowDownOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { Link } from 'react-scroll';

const { Text } = Typography;

export default function MobileLandingPage() {
  return (
    <div
      className="mobilelanding"
      style={{
        backgroundRepeat: 'no-repeat',
        textAlign: 'center',

        display: 'flex',
        position: 'relative',
        left: '-25px',
        width: '100vw',
        minHeight: '80vh',
        flexDirection: 'column',
      }}
    >
      <Text
        className="mobilelandingtext"
        style={{ fontSize: '3.3rem', zIndex: '3', color: 'white', height:'75vh', display: 'flex',
        alignItems: 'center', justifyContent:'space-evenly'}}
      >
        Jeremy Ho. 
        <br />
        
         Software Engineer.
      </Text>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '15vh',
        }}
      >
        <Link
          activeClass="active"
          className="navbar-item fontSmall"
          duration={500}
          offset={-60}
          smooth={true}
          spy={true}
          to="resume"
        >
          <ArrowDownOutlined
            className="arrowanimation"
            style={{ fontSize: '3rem', color: '#004eff', fill: '#004eff' }}
          />
        </Link>
      </div>
    </div>
  );
}
