import { Typography } from 'antd';

const { Text } = Typography;

export default function LandingPage() {
  return (
    <div
      className="landing"
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '75vh',
        textAlign: 'center',
      }}
    >
      <Text style={{ fontSize: '6rem', zIndex: '3' }}>
        <span className="jeremy">
          Jeremy Ho.
          <div
            className="avatarcontainer"
            style={{
              position: 'absolute',
              top: '10vh',
              right: '50vw',
            }}
          >
            <img
              alt="avatarleft"
              className="jeremyavatarlefticon"
              src="https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/SVG/Group+-1.svg"
            />
            <img
              alt="avatarright"
              className="jeremyavatarrighticon"
              src="https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/SVG/Group+-2.svg"
            />
            <img
              alt="avatar"
              className="jeremyavatar"
              src="https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/Jho%402x.png"
            />
          </div>
        </span>{' '}
        
      </Text>
      <Text style={{ fontSize: '6rem', zIndex: '3' }}></Text>
      <Text
        style={{
          fontSize: '2rem',
          zIndex: '2',
          width: '-webkit-fill-available',
        }}
      >
        
        <span className="losangeles">
        Software Engineer.
          <div
            className="citycontainer"
            style={{
              position: 'absolute',
              bottom: '30vh',
              left: '55vw',
            }}
          >
            <img
              alt="cityavatarleft"
              className="cityavatarlefticon"
              src="https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/SVG/Group+-3.svg"
            />
            <img
              alt="cityavatarright"
              className="cityavatarrighticon"
              src="https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/SVG/Group+-4.svg"
            />
            <img
              alt="avatar right"
              className="cityavatarbottomrighticon"
              src="https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/SVG/Group+-5.svg"
            />
            <img
              alt="city avatar"
              className="cityavatar"
              src="https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/LA%402x.png"
            />
          </div>
        </span>
      </Text>
    </div>
  );
}
